import React, { useState } from "react";
import { Link } from 'gatsby'
import Sidebar from '../components/sidebar'
import Layout from '../components/layout'
import Seo from '../components/seo'
import PostItem from '../components/blog-post-item'

export default ({ pageContext }) => {  

    const [childData, setChildData] = useState("");

    return (
        <Layout className="page-blog page-blog-list">
            <Seo 
                title="Blog de DMS empresa de Servicios Informáticos de Barcelona"
                description="Blog de DMS sobre IBM Informix, Workforce Productivity, SP-Expert Sistemas, ITIL, Lean Startup y Cloud"
                />
            <section className="blog-content container">
                <div className="blog-content-main">
                    { 
                        childData ? childData :

                        pageContext.posts.map(item => {
                            const post = item.node;
                            return(<PostItem {...post } />)
                        })
                    }

                    {!childData &&
                        <div className="pagination">
                            {Array.from({length: pageContext.numberOfPages}).map((page, index) => (
                                <Link key={index} className={`btn btn-accent ${index+1 === pageContext.currentPage ? 'page-active' : ''}`} to={index === 0 ? '/blog' : `/blog/page/${index + 1}`}>
                                    {index + 1}
                                </Link>
                            ))}
                        </div>
                    }
                </div>

                <Sidebar passChildData={setChildData}/>
            </section>
        </Layout>
    )
}
